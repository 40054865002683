import React from "react";
import loadable from "@loadable/component";

const AccordionV1 = loadable(() => import("./AccordionV1"));

const Accordion = props => {
  const { designVersion } = props;

  switch (designVersion) {
    default:
    case "version1":
      return <AccordionV1 {...props} />;
  }
};

export default Accordion;
